var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-section',{attrs:{"id":"info-alt","space":"56"}},[_c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('base-business-info',{attrs:{"title":_vm.$t('contact-section.business-info.title'),"content":_vm.$t('contact-section.business-info.content'),"business":[
            {
              icon: 'mdi-map-marker-outline',
              title: 'Address',
              text: 'Av. Insurgentes Sur #64, Col. Juarez, CDMX',
            },
            {
              icon: 'mdi-cellphone',
              title: 'Phone',
              text: '+52 (55) 5254 7000',
            },
            {
              icon: 'mdi-email',
              title: 'Email',
              text: 'contacto@hubiter.biz',
            },
          ]}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-contact-form',{attrs:{"title":_vm.$t('contact-section.base-contact-form.title'),"href":"mailto:contacto@hubiter.biz?subject=Contacto"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }